import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Loading: React.FC = () => (
  <div className="d-flex flex-row justify-content-center align-items-center w-100 h-100">
    <FontAwesomeIcon icon={faSpinner} size="2x" spin className="mt-2" />
  </div>
);

export default React.memo(Loading);
