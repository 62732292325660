import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import firebase from "../utils/firebase";

export interface ImageProps {
  uri: string;
  alt?: string;
  className?: string;
}

const Image: React.SFC<ImageProps> = (props) => {
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    getDownloadUrl(props.uri);
  }, [props.uri]);

  const getDownloadUrl = async (uri: string) => {
    const url = await firebase.storage().refFromURL(props.uri).getDownloadURL();
    setUrl(url);
  };

  if (isEmpty(url)) {
    return null;
  }

  return <img src={url} alt={props.alt} className={props.className} />;
};

export default Image;
